import React from "react";
import SiteLayout from "../components/SiteLayout";
import cx from "classnames";

import Block from "../components/Block";
import Title from "../components/Title";
import style from "../components/Wysiwyg/wysiwyg.module.sass";
import Seo from "../components/Seo";
import Breadcrumb from "../components/BreadCrumb";

export default class KarieraPage extends React.Component {
    render() {
        return (
            <SiteLayout
                location={this.props.location}
                formButtonLabel="Máte zájem být naším poradcem?"
                formButtonLink="/chcete-se-stat-poradcem"
            >
                <Seo
                    title={"Kariéra poradce pro investiční kovy"}
                    description={
                        "Pomůžeme vám s kariérou investičního poradce pro zlato a další kovy. Máte naši plnou podporu v začátcích i později."
                    }
                />
                <Breadcrumb
                    crumbs={[
                        {
                            label: "Kariéra",
                            link: "kariera-poradce-pro-investicni-kovy",
                        },
                    ]}
                />
                <Block leftIndent>
                    <Title type={"h1"} color={"blueDark"} mb={50} mt={30}>
                        Staňte se poradcem
                        <br /> investic do zlata
                    </Title>
                </Block>

                <Block radialGradient>
                    <div className={cx(style.wysiwyg, style.wysiwygText)}>
                        <h3>Podporujeme nováčky</h3>
                        <p>
                            Pomůžeme vám v začátcích, naučíme vás základy a
                            vyřídíme za vás veškerou administrativu. Díky našemu
                            unikátnímu manuálu a metodice můžete začít v oboru i
                            vy. Nováčkům je navíc k ruce pracovník, který vám
                            pomůže se všemi formalitami. Povinné vzdělávání k
                            investicím do zlata je přístupné pohodlně on-line.
                        </p>
                        <h3>Na cestě za úspěchem vás necháváme rozlétnout</h3>
                        <p>
                            Jsme s vámi, ať už chcete budovat vlastní tým nebo
                            mít roli samostatného poradce. Bez omezení můžete
                            rozvíjet vlastní kancelář nebo pobočku. Budujte
                            kariéru bez nutnosti obhajob a potvrzování pozice.
                        </p>
                        <br />
                        <img
                            src={require("../images/kariera-img.png")}
                            alt="Víte, ŽE české domácnosti vlastní stodvacetkrát méně zlata na osobu než německé?"
                        />
                        <h3>Spravedlivě rozdělujeme odměny</h3>
                        <p>
                            K dispozici máte detailní měsíční vyúčtování i
                            průběžný přehled. Garantujeme včasnou výplatu a
                            provizní podíl na všech maržích - nákupní, průběžné
                            i výkupní.
                        </p>
                        <br />
                    </div>
                </Block>
            </SiteLayout>
        );
    }
}
